import * as react from 'react';
import * as reactDom from 'react-dom';
import * as reactRedux from 'react-redux';
import * as reduxjsToolkit from '@reduxjs/toolkit';
import * as reduxjsToolkitQuery from '@reduxjs/toolkit/query';
import * as reactRouter from 'react-router';
import * as reactRouterDom from 'react-router-dom';
import * as reduxPersist from 'redux-persist';
import * as reduxSaga from 'redux-saga';
import * as reduxSagaEffects from 'redux-saga/effects';
import * as reduxActions from 'redux-actions';
import * as normalizr from 'normalizr';
// removed until redux-injectors support react-redux 8.1.1
// import * as reduxInjectors from 'redux-injectors';

export {
  react,
  reactDom,
  reactRedux,
  reduxjsToolkit,
  reduxjsToolkitQuery,
  reactRouter,
  reactRouterDom,
  reduxPersist,
  reduxSaga,
  reduxSagaEffects,
  reduxActions,
  // removed until redux-injectors support react-redux 8.1.1
  // reduxInjectors,
  normalizr,
};

export { default as Application, createOlafApplication, injectSaga, injectReducer } from './application';
export { fetch } from './sagas/defaultSagas';
export { resolveState } from './selectors/utils';
export { getAppName } from './selectors/defaultSelector';
